import { useCallback, useEffect, useRef, useState } from "react";
import { Event, LatLng } from "../../../../domain/model/Event";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../../../data/constant/Constants";
import { CreateEventUseCase } from "../../../../domain/usecase/event/create/CreateUseCase";
import { State } from "../../../../domain/model/ResponseState";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { ROUTE_ENDPOINTS } from "../../../../data/constant/RouteConstants";
import { uploadFile } from "../../../../core/utils/Utils";
import { provideUploadFileUseCase } from "../../../di/UseCasesModule";
import { GetEventUseCase } from "../../../../domain/usecase/event/getOne/GetOneUseCase";
import { UpdateEventUseCase } from "../../../../domain/usecase/event/update/UpdateUseCase";

export interface Place {
  description: string;
  placeId: string;
}

export function EventDetailsViewModel(
  createEventUseCase: CreateEventUseCase,
  getEventUseCase: GetEventUseCase,
  updateEventUseCase: UpdateEventUseCase
) {
  const [userLocation, setUserLocation] = useState<LatLng | undefined>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedPlaceText, setSearchedPlaceText] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [places, setPlaces] = useState<Array<Place>>([]);
  const navigate = useNavigate();

  const searchBoxParentRef = useRef(null);
  const [searchBoxParentWidth, setSearchBoxParentWidth] = useState(0);

  const [event, setEvent] = useState<Event | undefined>({
    name: "",
    description: "",
    isPaid: false,
    price: 0,
    reviewEnabled: true,
    reviewPrivate: false,
  });

  useEffect(() => {
    setSearchBoxParentWidth((searchBoxParentRef.current as any).offsetWidth);
  }, [searchBoxParentRef.current]);

  async function getEvent(id: string) {
    setIsLoading(true);
    const response = await getEventUseCase.invoke(id);
    switch (response.responseState) {
      case State.Success:
        if (map && response.data?.location)
          map.setCenter({
            lat: response.data.location.latitude,
            lng: response.data.location.longitude,
          });
        setEvent(response.data);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  async function updateEvent() {
    if (!event) return;
    if (!checkEventDetailsNotEmpty()) return;

    setIsLoading(true);
    if (event.audioFile) {
      const uploadedFile = await uploadFile(
        provideUploadFileUseCase(),
        event.audioFile
      );
      event.audioUrl = uploadedFile?.downloadUrl;
    }
    const response = await updateEventUseCase.invoke(event);
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        navigate(ROUTE_ENDPOINTS.HOME_EVENTS);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function checkEventDetailsNotEmpty(): boolean {
    if (!event) return false;

    if (!event.name) {
      showSnackbar("Event name cannot be empty");
      return false;
    }

    if (!event.description) {
      showSnackbar("Event description cannot be empty");
      return false;
    }

    if (!event.triggerDateString) {
      showSnackbar("Event trigger date cannot be empty");
      return false;
    }

    if (!event.location) {
      showSnackbar("Event location cannot be empty");
      return false;
    }

    if (event.isPaid && !event.price) {
      showSnackbar("Event price cannot be empty");
      return false;
    }
    
    if (!event._id && !event.audioFile) {
      showSnackbar("Event audio file cannot be empty");
      return false;
    }

    return true;
  }

  async function createEvent() {
    if (!event) return;
    if (!checkEventDetailsNotEmpty()) return;

    setIsLoading(true);
    if (event.audioFile) {
      const uploadedFile = await uploadFile(
        provideUploadFileUseCase(),
        event.audioFile
      );
      event.audioUrl = uploadedFile?.downloadUrl;
    }
    const response = await createEventUseCase.invoke(event);
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        navigate(ROUTE_ENDPOINTS.HOME_EVENTS);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  function getLatLngFromPlace(placeId: string) {
    if (!map) return;
    const placesService = new google.maps.places.PlacesService(map);
    placesService.getDetails(
      {
        placeId: placeId,
      },
      function (place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (!place?.geometry) return;
          console.log(place.geometry.location?.lat());

          setEvent({
            ...event,
            location: {
              latitude: place.geometry.location?.lat()!,
              longitude: place.geometry.location?.lng()!,
            },
          });
          setPlaces([]);
          setSearchedPlaceText("");
        }
      }
    );
  }

  function searchForPlaces(place: string) {
    const autoComplete = new window.google.maps.places.AutocompleteService();
    autoComplete.getPlacePredictions({ input: place }, (predictions) => {
      const places: Array<Place> = [];
      predictions?.map((p) => {
        places.push({ description: p.description, placeId: p.place_id });
      });
      setPlaces(places);
    });
  }

  const onGoogleMapLoad = useCallback(function callback(map: any) {
    map.setCenter({
      lat: 47.444,
      lng: -122.176,
    });
    setMap(map);

    if (map && event?.location)
      map.setCenter({
        lat: event.location.latitude,
        lng: event.location.longitude,
      });
    getUserLocation();
  }, []);

  const onGoogleMapUnmount = useCallback(function callback(_: any) {
    setMap(null);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  function success(pos: any) {
    var crd = pos.coords;
    console.log(crd);
    setUserLocation({ latitude: crd.latitude, longitude: crd.longitude });

    if (!map || event?._id) return;
    map.setCenter({
      lat: crd.latitude,
      lng: crd.longitude,
    });
  }

  useEffect(() => {
    if (userLocation && map && !event?._id) {
      if (event?.location) {
        map.setCenter({
          lat: event?.location.latitude,
          lng: event?.location.longitude,
        });
      } else {
        map.setCenter({
          lat: userLocation.latitude,
          lng: userLocation.longitude,
        });
      }
    }
  }, [userLocation]);

  useEffect(() => {
    if (map && event?.location) {
      map.setCenter({
        lat: event?.location.latitude,
        lng: event?.location.longitude,
      });
    }
  }, [event?.location]);

  function errors(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    // alert(`ERROR(${err.code}): ${err.message}`);
  }

  async function getUserLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    if (navigator.geolocation) {
      const result = await navigator.permissions.query({ name: "geolocation" });
      if (result.state === "granted") {
        navigator.geolocation.getCurrentPosition(success, errors, options);
      } else if (result.state === "prompt") {
        navigator.geolocation.getCurrentPosition(success, errors, options);
      } else if (result.state === "denied") {
      }
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function checkCurrentLocation() {
    if (userLocation && map) {
      map.setCenter({
        lat: userLocation.latitude,
        lng: userLocation.longitude,
      });
    } else {
      getUserLocation();
    }
  }

  return {
    userLocation,
    getUserLocation,
    onGoogleMapLoad,
    isLoaded,
    onGoogleMapUnmount,
    checkCurrentLocation,
    openDialog,
    setOpenDialog,
    event,
    setEvent,
    getEvent,
    isLoading,
    createEvent,
    updateEvent,
    searchForPlaces,
    setSearchedPlaceText,
    searchedPlaceText,
    places,
    setPlaces,
    searchBoxParentRef,
    searchBoxParentWidth,
    getLatLngFromPlace,
  };
}
