import {
  AccessToken,
  Auth,
  IdModel,
  Message,
  SignupRequestModel,
} from "../../domain/model/Auth";
import {
  Company,
  CreateCompanyRequestModel,
  UpdateCompanyRequestModel,
} from "../../domain/model/Company";
import { Event } from "../../domain/model/Event";
import { FileModel } from "../../domain/model/File";
import { ApiService } from "../api/ApiService";
import { Response } from "../api/Response";
import { API_ENDPOINTS } from "../constant/ApiConstants";
import NetworkApi from "./NetworkApi";

export default class NetworkApiImpl implements NetworkApi {
  apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  login(auth: Auth): Promise<Response<AccessToken>> {
    return this.apiService.post<AccessToken>(API_ENDPOINTS.LOGIN, auth);
  }
  signup(model: SignupRequestModel): Promise<Response<IdModel>> {
    return this.apiService.post<IdModel>(API_ENDPOINTS.SIGNUP, model);
  }

  uploadFile(fileFormData: FormData): Promise<Response<FileModel>> {
    return this.apiService.post<FileModel>(
      API_ENDPOINTS.UPLOAD_FILE,
      fileFormData
    );
  }

  // company
  createCompany(
    company: CreateCompanyRequestModel
  ): Promise<Response<IdModel>> {
    return this.apiService.post<IdModel>(API_ENDPOINTS.COMPANIES, company);
  }
  getCompanies(
    skip?: number,
    limit?: number
  ): Promise<Response<Array<Company>>> {
    var url = `${API_ENDPOINTS.COMPANIES}`;
    if (limit !== undefined) {
      url += `?limit=${limit}`;
      if (skip !== undefined) {
        url += `&skip=${skip}`;
      }
    }
    return this.apiService.get<Array<Company>>(url);
  }
  getCompanyDetails(): Promise<Response<Company>> {
    return this.apiService.get<Company>(API_ENDPOINTS.COMPANIES);
  }
  updateCompany(
    id: string,
    company: UpdateCompanyRequestModel
  ): Promise<Response<Message>> {
    return this.apiService.put<Message>(
      `${API_ENDPOINTS.COMPANIES}/${id}`,
      company
    );
  }

  // event
  createEvent(event: Event): Promise<Response<Message>> {
    return this.apiService.post<Message>(API_ENDPOINTS.EVENTS, event);
  }
  getEvents(skip?: number, limit?: number): Promise<Response<Array<Event>>> {
    var url = `${API_ENDPOINTS.EVENTS}`;
    if (limit !== undefined) {
      url += `?limit=${limit}`;
      if (skip !== undefined) {
        url += `&skip=${skip}`;
      }
    }
    return this.apiService.get<Array<Event>>(url);
  }
  getEvent(id: string): Promise<Response<Event>> {
    return this.apiService.get<Event>(`${API_ENDPOINTS.EVENTS}/${id}`);
  }
  updateEvent(event: Event): Promise<Response<Message>> {
    return this.apiService.put<Message>(
      `${API_ENDPOINTS.EVENTS}/${event._id}`,
      event
    );
  }
}
