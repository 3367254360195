import { Auth, AccessToken, Message } from "../../../domain/model/Auth";
import { Event } from "../../../domain/model/Event";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { EventRepository } from "./EventRepository";

export class EventRepositoryImpl implements EventRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }

  create(event: Event): Promise<Response<Message>> {
    return this.apiService.createEvent(event);
  }
  getAll(skip?: number, limit?: number): Promise<Response<Array<Event>>> {
    return this.apiService.getEvents(skip, limit);
  }
  getOne(id: string): Promise<Response<Event>> {
    return this.apiService.getEvent(id);
  }
  update(event: Event): Promise<Response<Message>> {
    return this.apiService.updateEvent(event);
  }
}
