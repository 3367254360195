import { IconButton, Stack } from "@mui/material";
import { Event } from "../../../domain/model/Event";
import { CustomCardView } from "../../component/CustomCardView";
import { CustomTextView } from "../../component/CustomTextView";
import {
  COLOR_BLACK,
  COLOR_LIGHT_GRAY,
} from "../../../data/colors/Colors";
import { ArrowCircleRightRounded } from "@mui/icons-material";
import { ROUTE_ENDPOINTS } from "../../../data/constant/RouteConstants";
import { formatDate } from "../../../core/utils/Utils";
import Player from "../../component/CustomAudioPlayer";

interface p {
  event: Event;
  index: number;
}

const NAME_MAX_LENGTH = 40;
const DESCRIPTION_MAX_LENGTH = 200;

export function EventsListItemView(p: p) {
  const event = p.event;
  const index = p.index;
  return (
    <CustomCardView
      padding={3}
      children={
        <Stack spacing={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <CustomTextView
                text={`#${index + 1}`}
                fontWeight="bold"
                fontSize={20}
              />
              <CustomTextView
                text={`${event.name?.substring(
                  0,
                  event.name.length > NAME_MAX_LENGTH
                    ? NAME_MAX_LENGTH
                    : event.name.length
                )} ${event.name!.length > NAME_MAX_LENGTH ? "..." : ""}`}
                fontWeight="bold"
                fontSize={24}
              />
            </Stack>

            <IconButton href={`${ROUTE_ENDPOINTS.HOME_EVENTS}/${event._id}`}>
              <ArrowCircleRightRounded
                sx={{ color: COLOR_BLACK }}
                fontSize="large"
              />
            </IconButton>
          </Stack>
          <CustomTextView
            text={`${event.description?.substring(
              0,
              event.description.length > DESCRIPTION_MAX_LENGTH
                ? DESCRIPTION_MAX_LENGTH
                : event.description.length
            )} ${
              event.description!.length > DESCRIPTION_MAX_LENGTH ? "..." : ""
            }`}
            fontSize={18}
          />

          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            justifyContent={"space-between"}
          >
            <CustomTextView
              text="Scheduled at"
              textColor={COLOR_LIGHT_GRAY}
              fontWeight="300"
            />
            <CustomTextView
              text={formatDate(new Date(event.triggerDateString ?? new Date()))}
              fontWeight="bold"
            />
          </Stack>
          <Player url={event.audioUrl}/>
        </Stack>
      }
    />
  );
}
