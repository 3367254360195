import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { EventsViewModel } from "./EventsViewModel";
import {
  provideCreateEventUseCase,
  provideGetAllEventsUseCase,
  provideGetCompanyDetailsUseCase,
} from "../../di/UseCasesModule";
import { CustomTextView } from "../../component/CustomTextView";
import { STRINGS_EVENTS } from "../../../data/strings/Strings";
import {
  COLOR_LESS_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { AddRounded } from "@mui/icons-material";
import { EmptyView } from "../../component/EmptyView";
import {
  ROUTE_ENDPOINTS,
  ROUTE_NEW,
} from "../../../data/constant/RouteConstants";
import { EventsListItemView } from "./EventsListItemView";
import { LoadingView } from "../../component/LoadingView";

import StackGrid, { transitions } from "react-stack-grid";
import useWindowDimensions from "../../../core/utils/UseWindowDimensions";

const { flip } = transitions;

export function EventsView() {
  const viewModel = EventsViewModel(
    provideGetCompanyDetailsUseCase(),
    provideGetAllEventsUseCase()
  );

  const { width } = useWindowDimensions();
  return (
    <Box maxWidth={"100%"}>
      <Stack p={3}>
        <Stack
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
          pb={2}
        >
          <CustomTextView
            text={STRINGS_EVENTS}
            variant="h4"
            fontWeight="bold"
            textColor={COLOR_MAIN}
          />
          {viewModel.createEventCheckingLoading ? (
            <CircularProgress sx={{ color: COLOR_MAIN }} />
          ) : (
            <IconButton
              LinkComponent={"a"}
              href={`${ROUTE_ENDPOINTS.HOME_EVENTS}/${ROUTE_NEW}`}
              sx={{ background: COLOR_MAIN }}
              disabled={!viewModel.canCreateEvent}
            >
              <AddRounded
                sx={{
                  color: viewModel.canCreateEvent
                    ? COLOR_WHITE
                    : COLOR_LESS_BLACK,
                }}
                fontSize="large"
              />
            </IconButton>
          )}
        </Stack>
        <>
          {viewModel.isLoading && viewModel.events?.length === 0 ? (
            <LoadingView />
          ) : (
            <>
              {viewModel.events && (
                <>
                  {viewModel.events.length === 0 ? (
                    <EmptyView />
                  ) : (
                    <Stack spacing={2}>
                      <StackGrid
                        appear={flip.appear}
                        appeared={flip.appeared}
                        enter={flip.enter}
                        entered={flip.entered}
                        leaved={flip.leaved}
                        gutterHeight={10}
                        gutterWidth={10}
                        columnWidth={
                          width <= 900
                            ? "100%"
                            : width <= 1400
                            ? "50%"
                            : "33.33%"
                        }
                        duration={700}
                      >
                        {viewModel.events.map((event, index) => {
                          return (
                            <EventsListItemView
                              event={event}
                              index={index}
                              key={event._id}
                            />
                          );
                        })}
                      </StackGrid>
                      {viewModel.isLoading ? (
                        <LoadingView minHeight="5vh" />
                      ) : (
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          {viewModel.showLoadMoreButton && (
                            <Button
                              variant="outlined"
                              sx={{
                                borderRadius: 22,
                                textTransform: "none",
                                borderColor: COLOR_MAIN,
                                background: COLOR_MAIN,
                                "&:hover": {
                                  borderColor: COLOR_MAIN,
                                  background: COLOR_LIGHT_GRAY,
                                },
                              }}
                              onClick={viewModel.getAllEvents}
                            >
                              <CustomTextView
                                text={"Load More"}
                                fontSize={20}
                                fontWeight="bold"
                              />
                            </Button>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Stack>
    </Box>
  );
}
