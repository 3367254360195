import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { CompaniesViewModel } from "./CompaniesViewModel";
import { CustomTextView } from "../../component/CustomTextView";
import { STRINGS_COMPANIES } from "../../../data/strings/Strings";
import {
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { LoadingView } from "../../component/LoadingView";
import { EmptyView } from "../../component/EmptyView";
import { CompaniesListItemView } from "./CompaniesListItemView";
import {
  provideGetAllCompaniesUseCase,
  provideUpdateCompanyUseCase,
} from "../../di/UseCasesModule";

export function CompaniesView() {
  const viewModel = CompaniesViewModel(
    provideGetAllCompaniesUseCase(),
    provideUpdateCompanyUseCase()
  );

  return (
    <Box maxWidth={"100%"}>
      <Backdrop
        sx={{ color: COLOR_WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewModel.isBackdropLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack pl={1} pr={1} pt={3} pb={3}>
        <Stack
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
          pb={2}
          pl={2}
          pr={2}
        >
          <CustomTextView
            text={STRINGS_COMPANIES}
            variant="h4"
            fontWeight="bold"
            textColor={COLOR_MAIN}
          />
        </Stack>
        <>
          {viewModel.isLoading && viewModel.companies?.length === 0 ? (
            <LoadingView />
          ) : (
            <>
              {viewModel.companies && (
                <>
                  {viewModel.companies.length === 0 ? (
                    <EmptyView />
                  ) : (
                    <Stack spacing={2}>
                      <Container maxWidth={false}>
                        <Grid container spacing={2}>
                          {viewModel.companies.map((company, index) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                key={company.user?._id}
                              >
                                {" "}
                                <CompaniesListItemView
                                  company={company}
                                  index={index}
                                  onVerifyClicked={
                                    viewModel.verifyUnVerifyCompany
                                  }
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Container>
                      {viewModel.isLoading ? (
                        <LoadingView minHeight="5vh" />
                      ) : (
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          {viewModel.showLoadMoreButton && (
                            <Button
                              variant="outlined"
                              sx={{
                                borderRadius: 22,
                                textTransform: "none",
                                borderColor: COLOR_MAIN,
                                background: COLOR_MAIN,
                                "&:hover": {
                                  borderColor: COLOR_MAIN,
                                  background: COLOR_LIGHT_GRAY,
                                },
                              }}
                              onClick={viewModel.getCompanies}
                            >
                              <CustomTextView
                                text={"Load More"}
                                fontSize={20}
                                fontWeight="bold"
                              />
                            </Button>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Stack>
    </Box>
  );
}
