import dateAndTime from "date-and-time";
import { UploadFileUseCase } from "../../domain/usecase/uploadFile/UploadFileUseCase";
import { FileModel } from "../../domain/model/File";
import { State } from "../../domain/model/ResponseState";

export function formatDate(
  date: Date,
  format: string = "DD MMM YYYY hh:mm:ss A"
): string {
  return dateAndTime.format(new Date(date), format);
}

export async function uploadFile(
  uploadFileUseCase: UploadFileUseCase,
  file: File
): Promise<FileModel | undefined> {
  const formData = new FormData();
  formData.append("file", file);
  const response = await uploadFileUseCase.invoke(formData);
  switch (response.responseState) {
    case State.Success:
      return response.data;
    case State.Fail:
    case State.Error:
      return undefined;
  }
}
