import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ROUTE_NEW } from "../../../../data/constant/RouteConstants";
import React, { useEffect } from "react";
import { CustomTextView } from "../../../component/CustomTextView";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import {
  COLOR_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../../data/colors/Colors";
import { CustomCardView } from "../../../component/CustomCardView";
import { CustomTextField } from "../../../component/CustomTextField";
import { GoogleMap, Marker } from "@react-google-maps/api";
import {
  MAIN_FONT_FAMILY,
  MAP_DARK_STYLE,
} from "../../../../data/constant/Constants";
import {
  AudioFile,
  AudiotrackSharp,
  DateRange,
  PinDropRounded,
} from "@mui/icons-material";
import { EventDetailsViewModel } from "./EventDetailsViewModel";
import { MainColoredSwitch } from "../../../component/CustomSwitch";
import { FilePicker } from "../../../component/FilePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { formatDate } from "../../../../core/utils/Utils";
import Player from "../../../component/CustomAudioPlayer";
import {
  provideCreateEventUseCase,
  provideGetEventUseCase,
  provideUpdateEventUseCase,
} from "../../../di/UseCasesModule";
import { NumericFormat } from "react-number-format";

export function EventDetailsView() {
  const { id } = useParams();
  const isNewPage = id === ROUTE_NEW;

  const viewModel = EventDetailsViewModel(
    provideCreateEventUseCase(),
    provideGetEventUseCase(),
    provideUpdateEventUseCase()
  );

  useEffect(() => {
    if (!isNewPage && id) {
      viewModel.getEvent(id);
    }
  }, []);

  return (
    <Box>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={viewModel.openDialog}
        onClose={() => viewModel.setOpenDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 6,
            p: 2,
            minHeight: "30vh",
          },
        }}
      >
        <Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <StaticDateTimePicker
                disablePast
                defaultValue={dayjs(new Date().toISOString())}
                onChange={(date) => {
                  viewModel.setEvent({
                    ...viewModel.event,
                    triggerDate: date?.toDate(),
                    triggerDateString: date?.toDate().toISOString(),
                  });
                }}
                slotProps={{
                  layout: { sx: { fontFamily: MAIN_FONT_FAMILY } },
                  actionBar: { actions: ["today"] },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Button
            onClick={() => viewModel.setOpenDialog(false)}
            variant="outlined"
            sx={{ borderRadius: 12, width: 100, alignSelf: "end" }}
          >
            <CustomTextView text="Set" variant="h6" fontWeight="bold" />
          </Button>
        </Stack>
      </Dialog>
      <Stack>
        <Stack
          pl={3}
          pr={3}
          pt={3}
          pb={3}
          alignItems={"center"}
          direction={"row"}
          justifyContent={isNewPage ? "center" : "space-between"}
          spacing={2}
        >
          <CustomTextView
            text={isNewPage ? "New Event" : `Event #${id?.substring(0, 5)}...`}
            variant="h4"
            fontWeight="bold"
            textColor={COLOR_MAIN}
          />

          {viewModel.isLoading ? (
            <CircularProgress sx={{ color: COLOR_MAIN }} size={30} />
          ) : (
            <Button
              sx={{ background: "green", borderRadius: 12 }}
              onClick={() => {
                if (isNewPage) {
                  viewModel.createEvent();
                } else {
                  viewModel.updateEvent();
                }
              }}
            >
              <CustomTextView
                text="Save"
                props={{ textTransform: "none" }}
                fontWeight="bold"
                variant="h6"
                textColor={COLOR_WHITE}
              />
            </Button>
          )}
        </Stack>

        <Stack pl={3} pr={3} pb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <TitledView
                title="Name"
                description="Type the name of the event"
                layout={
                  <CustomTextField
                    placeholder="Name"
                    value={viewModel.event?.name}
                    onTextChange={(txt) => {
                      viewModel.setEvent({ ...viewModel.event, name: txt });
                    }}
                    backgroundColor={COLOR_WHITE}
                    textColor={COLOR_BLACK}
                    borderColor={COLOR_BLACK}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <TitledView
                title="Description"
                description="Type the description of the event"
                layout={
                  <CustomTextField
                    placeholder="Description"
                    value={viewModel.event?.description}
                    onTextChange={(txt) => {
                      viewModel.setEvent({
                        ...viewModel.event,
                        description: txt,
                      });
                    }}
                    backgroundColor={COLOR_WHITE}
                    textColor={COLOR_BLACK}
                    borderColor={COLOR_BLACK}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <TitledView
                title="Review Enabled"
                description="Whether review is enabled or not, if so is review private or not"
                button={
                  <MainColoredSwitch
                    checked={viewModel.event?.reviewEnabled}
                    onChange={(_, checked) => {
                      viewModel.setEvent({
                        ...viewModel.event,
                        reviewEnabled: checked,
                      });
                    }}
                    inputProps={{
                      "aria-label": "controlled",
                      color: COLOR_MAIN,
                    }}
                    color="warning"
                    sx={{ color: COLOR_MAIN }}
                  />
                }
                layout={
                  <Box
                    width={"100%"}
                    sx={
                      viewModel.event?.reviewEnabled
                        ? undefined
                        : { filter: "blur(6px)", pointerEvents: "none" }
                    }
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <CustomTextView
                        text="Review Private"
                        variant="h6"
                        fontWeight="bold"
                      />
                      <MainColoredSwitch
                        checked={viewModel.event?.reviewPrivate}
                        onChange={(_, checked) => {
                          viewModel.setEvent({
                            ...viewModel.event,
                            reviewPrivate: checked,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                          color: COLOR_MAIN,
                        }}
                        color="warning"
                        sx={{ color: COLOR_MAIN }}
                      />
                    </Stack>
                  </Box>
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TitledView
                title="Is Paid? ($USD)"
                description="Whether the event is free or not"
                button={
                  <MainColoredSwitch
                    checked={viewModel.event?.isPaid}
                    onChange={(_, checked) => {
                      viewModel.setEvent({
                        ...viewModel.event,
                        isPaid: checked,
                      });
                    }}
                    inputProps={{
                      "aria-label": "controlled",
                      color: COLOR_MAIN,
                    }}
                    color="warning"
                    sx={{ color: COLOR_MAIN }}
                  />
                }
                layout={
                  <Box
                    width={"100%"}
                    sx={
                      viewModel.event?.isPaid
                        ? undefined
                        : { filter: "blur(6px)", pointerEvents: "none" }
                    }
                  >
                    <NumericFormat
                      value={viewModel.event?.price}
                      thousandSeparator
                      prefix={"$"}
                      placeholder="Price ($USD)"
                      customInput={TextField}
                      onValueChange={(price) => {
                        viewModel.setEvent({
                          ...viewModel.event,
                          price: price.floatValue,
                        });
                      }}
                      {...{
                        InputProps: { sx: { borderRadius: 44 } },
                        sx: {
                          background: COLOR_WHITE,
                          borderRadius: 44,
                          width: "100%",
                          fontFamily: MAIN_FONT_FAMILY,
                          borderColor: COLOR_BLACK,
                          "& .MuiOutlinedInput-root": {
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: COLOR_BLACK,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: COLOR_BLACK,
                            },
                          },
                        },
                      }}
                    />
                    {/* <CustomTextField
                      placeholder="Price ($USD)"
                      prefix="$"
                      value={`${viewModel.event?.price}`}
                      onTextChange={(txt) => {
                        const defaultOptions = {
                          significantDigits: 2,
                          thousandsSeparator: ',',
                          decimalSeparator: '.',
                          symbol: '$'
                        }
                        
                        const currencyFormatter = (value: any, options: any) => {
                          if (typeof value !== 'number') value = 0.0
                          options = { ...defaultOptions, ...options }
                          value = value.toFixed(options.significantDigits)
                        
                          const [currency, decimal] = value.split('.')
                          return `${options.symbol} ${currency.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            options.thousandsSeparator
                          )}${options.decimalSeparator}${decimal}`
                        }
                        // const price = txt.split(" ")[0]
                        
                        viewModel.setEvent({
                          ...viewModel.event,
                          price: Number(),
                        });
                      }}
                      backgroundColor={COLOR_WHITE}
                      textColor={COLOR_BLACK}
                      borderColor={COLOR_BLACK}
                      type="number"
                    /> */}
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TitledView
                title="Trigger Date"
                description="The date that the event is going to be triggered at"
                button={
                  <IconButton
                    sx={{ background: COLOR_BLACK }}
                    onClick={() => viewModel.setOpenDialog(true)}
                  >
                    <DateRange sx={{ color: COLOR_WHITE }} />
                  </IconButton>
                }
                layout={
                  <CustomTextView
                    text={
                      viewModel.event?.triggerDateString
                        ? formatDate(
                            new Date(viewModel.event?.triggerDateString)
                          )
                        : "no date chosen yet"
                    }
                    textColor={
                      viewModel.event?.triggerDateString
                        ? COLOR_BLACK
                        : COLOR_LIGHT_GRAY
                    }
                    fontWeight={
                      viewModel.event?.triggerDateString ? "bold" : "300"
                    }
                    variant={viewModel.event?.triggerDateString ? "h5" : "h6"}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <TitledView
                title="Location"
                description="Click anywhere on the map to set the location of the event"
                button={
                  <IconButton
                    sx={{ background: COLOR_BLACK }}
                    onClick={() => viewModel.checkCurrentLocation()}
                  >
                    <PinDropRounded sx={{ color: COLOR_WHITE }} />
                  </IconButton>
                }
                hasBoxAsParentLayout
                layoutPlacement={viewModel.isLoaded ? "end" : "center"}
                layout={
                  <>
                    <Box
                      component={"form"}
                      ref={viewModel.searchBoxParentRef}
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (viewModel.searchedPlaceText.length > 0)
                          viewModel.searchForPlaces(
                            viewModel.searchedPlaceText
                          );
                      }}
                    >
                      <CustomTextField
                        value={viewModel.searchedPlaceText}
                        placeholder="Type any place you want"
                        type="search"
                        backgroundColor={COLOR_WHITE}
                        textColor={COLOR_BLACK}
                        borderColor={COLOR_BLACK}
                        onTextChange={(txt) => {
                          viewModel.setSearchedPlaceText(txt);
                          if (txt.length == 0) {
                            viewModel.setPlaces([]);
                          }
                        }}
                      />
                      {viewModel.places.length > 0 && (
                        <Box
                          sx={{
                            zIndex: 1,
                            position: "absolute",
                            height: 200,
                            width: viewModel.searchBoxParentWidth,
                          }}
                        >
                          <CustomCardView
                            padding={1}
                            background={COLOR_WHITE}
                            borderRadius={2}
                            children={
                              <Stack spacing={1}>
                                {viewModel.places.map((p, index) => {
                                  return (
                                    <Button
                                      onClick={() =>
                                        viewModel.getLatLngFromPlace(p.placeId)
                                      }
                                    >
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"start"}
                                        width={"100%"}
                                        spacing={1}
                                      >
                                        <CustomTextView
                                          text={`#${index + 1}`}
                                          textColor={COLOR_LIGHT_GRAY}
                                        />
                                        <CustomTextView
                                          text={p.description}
                                          fontWeight="bold"
                                          props={{ textTransform: "none" }}
                                        />
                                      </Stack>
                                    </Button>
                                  );
                                })}
                              </Stack>
                            }
                          />
                        </Box>
                      )}
                    </Box>
                    {viewModel.isLoaded ? (
                      <CustomCardView
                        padding={0}
                        height={"100%"}
                        background={COLOR_BLACK}
                        children={
                          <GoogleMap
                            mapContainerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                            zoom={16}
                            onLoad={viewModel.onGoogleMapLoad}
                            clickableIcons={false}
                            onUnmount={viewModel.onGoogleMapUnmount}
                            onClick={(e) => {
                              if (e.latLng?.lat && e.latLng.lng)
                                viewModel.setEvent({
                                  ...viewModel.event,
                                  location: {
                                    latitude: e.latLng.lat(),
                                    longitude: e.latLng.lng(),
                                  },
                                });
                            }}
                            options={{
                              disableDefaultUI: false,
                              scaleControl: false,
                              rotateControl: false,
                              mapTypeControl: false,
                              fullscreenControl: false,
                              streetViewControl: false,
                              keyboardShortcuts: false,
                              styles: MAP_DARK_STYLE,
                            }}
                          >
                            {viewModel.userLocation && (
                              <Marker
                                icon={{
                                  url: require("../../../../assets/images/pin_location.png"),
                                  anchor: new google.maps.Point(17, 46),
                                  scaledSize: new google.maps.Size(28, 28),
                                }}
                                position={{
                                  lat: viewModel.userLocation.latitude,
                                  lng: viewModel.userLocation.longitude,
                                }}
                              />
                            )}

                            {viewModel.event?.location && (
                              <Marker
                                icon={{
                                  url: require("../../../../assets/images/event_pin.png"),
                                  anchor: new google.maps.Point(17, 46),
                                  scaledSize: new google.maps.Size(42, 42),
                                }}
                                position={{
                                  lat: viewModel.event.location.latitude,
                                  lng: viewModel.event.location.longitude,
                                }}
                              />
                            )}
                          </GoogleMap>
                        }
                      />
                    ) : (
                      <Stack
                        height={"100%"}
                        width={"100%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        spacing={2}
                      >
                        <CircularProgress sx={{ color: COLOR_WHITE }} />
                        <CustomTextView
                          text="Loading Google map..."
                          textColor={COLOR_WHITE}
                        />
                      </Stack>
                    )}
                  </>
                }
                height={500}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <TitledView
                title="Audio"
                description="Choose an audio to be played for the event"
                height={500}
                layoutPlacement={
                  viewModel.event?.audioUrl || viewModel.event?.audioFile
                    ? "end"
                    : "center"
                }
                button={
                  <IconButton sx={{ background: COLOR_BLACK }}>
                    <FilePicker
                      acceptType="audio/*,.m4a,.mp3,.ogg,.wav"
                      handleFilePicked={(file) => {
                        viewModel.setEvent({
                          ...viewModel.event,
                          audioFile: file,
                        });
                      }}
                      layout={<AudiotrackSharp sx={{ color: COLOR_WHITE }} />}
                    />
                  </IconButton>
                }
                layout={
                  <Stack
                    width={"100%"}
                    height={"100%"}
                    alignItems={"stretch"}
                    justifyContent={"space-between"}
                  >
                    <Box></Box>
                    <Stack alignItems={"center"}>
                      <AudioFile
                        sx={{ color: COLOR_BLACK, width: 80, height: 80 }}
                      />
                    </Stack>
                    {viewModel.event?.audioUrl || viewModel.event?.audioFile ? (
                      <Player
                        url={
                          viewModel.event?.audioFile
                            ? URL.createObjectURL(viewModel.event.audioFile)
                            : viewModel.event?.audioUrl
                        }
                      />
                    ) : (
                      <Box></Box>
                    )}
                  </Stack>
                }
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}

interface props {
  title: string;
  description: string;
  button?: React.ReactElement;
  layout: React.ReactElement;
  height?: number;
  layoutPlacement?: "end" | "center";
  hasBoxAsParentLayout?: boolean;
}
function TitledView(props: props) {
  const hasBox = props.hasBoxAsParentLayout ?? false;
  return (
    <CustomCardView
      background={COLOR_MAIN}
      padding={3}
      height={props.height ?? 200}
      children={
        <Stack spacing={3} height={"100%"}>
          <Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <CustomTextView
                text={props.title}
                textColor={COLOR_BLACK}
                fontSize={22}
                fontWeight="bold"
              />
              {props.button}
            </Stack>
            <CustomTextView
              text={props.description}
              textColor={COLOR_BLACK}
              fontSize={16}
            />
          </Stack>
          {hasBox ? (
            <>{props.layout}</>
          ) : (
            <Stack
              height={"100%"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={props.layoutPlacement ?? "end"}
            >
              {props.layout}
            </Stack>
          )}
        </Stack>
      }
    />
  );
}
